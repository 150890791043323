.home {
  width: 100%;
  min-height: 100vh;
  background-image: 
    url("../../assets/apiltd-coverpage.png");
    background-size: cover;
    background-position: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-text {
    text-align: center;
    max-width: 800px;
}
.home-text h1 {
    font-size: 40px;
    font-weight: 600;
    color: rgb(226, 237, 226);
}
.home-text p {
    max-width: 700px;
    margin: 10px auto 20px;
    color: #212121;
}
.btn-btn {
  background: #fff;
  color: #212121;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
}

/* -----media query----- */

@media (max-width: 768px) {
  .home-text h1 {
    font-size: 40px;
    text-align: center;
    padding-right: 60px;
  }
.btn-btn {
  align-items: center;
  margin-right: 60px;
}
}

@media (max-width: 480px) {
  .home-text h1 {
    font-size: 30px;
    max-width: 400px;
    margin: 15px auto 60px;
    display: inline;
    padding-right: 60px;
    
  }
  .home-text p {
font-size: 14px;
margin: 15px 0;
padding-right: 60px;
  }
}