.btn {
  background: #0b5504;
  color: #fef9f9;
  padding: 10px;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: 14px;
  font-weight: bold;
  border-radius: 30px;
}
/* -----media query----- */

@media (max-width: 768px) {
  .btn {
    margin-right: 60px;
  }
}
