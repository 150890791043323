.products {
  margin: 20px auto;
  width: 90%;
  text-align: center;
}
.product-img {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.product-img img {
  width: 23%;
  border-radius: 10px;
}

/* -----media query----- */

@media (max-width: 768px) {
  .products {
    flex-wrap: wrap;
  }
  .product-img img {
    width: 48%;
    margin-bottom: 25px;
  }
}
