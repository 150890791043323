.video-player{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-player video {
    width: 90%;
    max-width: 900px;
    height:auto;
    border: 4px solid #fff;
}

.hide {
    display: none;
}