.about {
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}
.about-left {
  flex-basis: 40%;
  position: relative;
}
.about-right {
  flex-basis: 56%;
}
.about-img {
  width: 100%;
  border-radius: 10px;
}
.play-icon {
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
  cursor: pointer;
}
.about h3 {
  font-weight: bold;
  font-size: 30px;
  color: orange;
}
.about h2 {
  font-size: 25px;
  color: #0b5504;
  margin: 10px 0;
  max-width: 400px;
}
.about p {
  color: #676767;
  margin-bottom: 10px;
}

/* -----media query----- */

@media (max-width: 768px) {
  .about {
    flex-direction: column;
    max-width: 60%;
    align-items: center;
  }
  .about-left {
    flex-basis: 100%;
    align-self: start;
    padding-right: 60px;
    order: 2;
  }
  .about-right {
    flex-basis: 100%;
  }
  .play-icon {
    width: 30px;
  }
}
