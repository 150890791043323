nav {
  width: 100%;
  /* background: rgba(252, 252, 252, 0.87); */
  color:#0b5504;
  font-size: 16px;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}
.container {
  max-width: 100%;
}
.nav-list {
  display: flex;
  gap: 20px;
}
.logo {
  padding-left: 30px;
  height: 40px;
  width: auto;
}
.nav-link {
  display: inline-block;
  list-style: none;
  margin: 5px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.nav-link:hover {
  color: rgb(191, 210, 191);
}
.dark-nav {
  background: #0b5504;
  color: rgb(191, 210, 191);
  transition: 0.5s;
  /*color: rgb(191, 210, 191);*/
}

.menu-icon {
  display: none;
  cursor: pointer;
}

/* ----- media query ----- */
@media (max-width: 768px) {
  
  nav { 
    padding: 15px 20px;
   }
  .nav-list { 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background: #0b5504;
    color: #d3e2d2;
    z-index: -1;
    width: 100px;
    padding-top: 0px;
    padding-left: 5px;
    transform: translateX(-100);
    transition: transform 0.3s ease; 
  }
  .container { 
    flex-direction: column;
  }

  .logo {
    padding-left: 10px;
    height: 25px;
    margin-bottom: 10px;
  }
  .nav-link {
    display: block;
    margin: 20px 0px;
    font-size: 14px;
  }
  .dark-nav {
    background:#0b5504;
  }
  .menu-icon {
    display: block;
    width: 30px;
    cursor: pointer;
  }
  
}
